import React, { Fragment, useCallback, useRef, useState } from 'react';
import Image from 'gatsby-image';
import { useMediaQuery } from 'react-responsive';
import { Parallax } from 'react-scroll-parallax';
import { size } from '~/constants/Breakpoint';
import { getImage } from '~/helpers';
import { Subheading, HeadingLarge, Heading, BodyTextAlt } from '~/components/.base/headings';
import { RedButton } from '~/components/.base/buttons';
import Slideshow from '~/components/.base/slideshow';
import { LocationModal } from '~/components/HomePage';
import './LocationMap.scss';
import MapItem from './MapItem';
import SideItem from './SlideItem';

const LocationMap = ({
    homepageImages,
    bgImages,
    openModal,
    homePageData,
    modalIsOpen,
    setModalIsOpen,
    activeLocation,
}) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });
    const [currentLocation, setCurrentLocation] = useState(0);
    const [activeLocationButton, setActiveLocationButton] = useState(null);
    const [activeLocationButtonMobile, setActiveLocationButtonMobile] = useState(null);

    const handleMarkerClick = i => {
        setCurrentLocation(i);
    };

    const handleChange = i => {
        setCurrentLocation(i);
    };

    const onModalClose = () => {
        if (isMobile) {
            if (activeLocationButtonMobile) {
                setTimeout(() => {
                    activeLocationButtonMobile.focus();
                }, 200);
            }
            return;
        }
        if (activeLocationButton) {
            setTimeout(() => {
                activeLocationButton.focus();
            }, 200);
        }
    };

    return (
        <Fragment>
            {!isMobile ? (
                <Parallax className="big_seagull" x={[-20, 50]}>
                    <Image fluid={getImage(homepageImages, 'big_seagull')} alt="" />
                </Parallax>
            ) : null}
            <Parallax className="seagull_flock" x={['-75px', '75px']}>
                <Image fluid={getImage(homepageImages, 'seagull_flock')} alt="" />
            </Parallax>
            {!isMobile ? (
                <Parallax className="seagull_medium" x={[5, 200]}>
                    <Image fluid={getImage(homepageImages, 'seagull_medium')} alt="" />
                </Parallax>
            ) : null}
            <div className="location-map">
                <div id="homepageOurlobstersorigins" tabIndex="-1">
                    <div className="locationMap__header">
                        <Subheading
                            as="p"
                            id="product-origins"
                            md="0 0 1rem"
                            className="locationMap__preheading"
                            dangerouslySetInnerHTML={{
                                __html: homePageData.locationSectionTitle,
                            }}
                        ></Subheading>
                        <HeadingLarge as="h2" className="locationMap__heading">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: homePageData.locationSectionTitle,
                                }}
                            ></span>
                        </HeadingLarge>
                    </div>
                    {homePageData && homePageData.locations ? (
                        <div className="map-cont" role="region" aria-label="Gulf of Maine Map">
                            <Image fluid={getImage(homepageImages, 'map')} alt="" />
                            <ul className="location-ports">
                                {homePageData.locations.map((location, i) => (
                                    <MapItem
                                        i={i}
                                        location={location}
                                        handleMarkerClick={handleMarkerClick}
                                        currentLocation={currentLocation}
                                        openModal={openModal}
                                        activeLocation={activeLocation}
                                        setActiveLocationButton={setActiveLocationButton}
                                    />
                                ))}
                            </ul>
                        </div>
                    ) : null}
                </div>
            </div>
            {isMobile && homePageData.locations && homePageData.locations.length ? (
                <Slideshow
                    carouselLabel="Location"
                    className="location-slideshow"
                    currentSlide={currentLocation}
                    handleChange={handleChange}
                >
                    {homePageData.locations.map((loc, i) => (
                        <SideItem
                            key={i}
                            loc={loc}
                            i={i}
                            openModal={openModal}
                            setActiveLocationButtonMobile={setActiveLocationButtonMobile}
                            activeLocation={activeLocation}
                        />
                    ))}
                </Slideshow>
            ) : null}
            <LocationModal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                activeLocation={activeLocation}
                homePageData={homePageData}
                onClose={onModalClose}
            />
        </Fragment>
    );
};

export default LocationMap;
